<template>
    <div @click="hideFollowModal">
        <a-breadcrumb class="breadcrumb">
            <a-breadcrumb-item>首页</a-breadcrumb-item>
            <a-breadcrumb-item>市场应用</a-breadcrumb-item>
            <a-breadcrumb-item>活动管理</a-breadcrumb-item>
            <a-breadcrumb-item>活动详情</a-breadcrumb-item>
        </a-breadcrumb>
        <div>
            <vue-element-loading :active="loading" color="#00cca2" spinner="spinner"/>
            <a-card title="" size="small" style="margin-bottom: 10px;">
                <a-row :gutter="24">
                    <a-col :span="12">
                    <span class="t">活动名称：</span><span class="v">{{ detail.meeting_name }}</span>
                    </a-col>
                    <!-- <a-col :span="12">
                    <span class="t">活动状态：</span><span class="v">{{ item.status }}</span>
                    </a-col> -->
                    <a-col :span="12">
                    <span class="t">合计收入：</span><span class="v">{{ detail.meeting_recevie_total }}元</span>
                    </a-col>
                    <a-col :span="12">
                    <span class="t">添加时间：</span><span class="v">{{ detail.created_at }}</span>
                    </a-col>
                    <a-col :span="12">
                    <span class="t">报名人次：</span><span class="v">{{ detail.already_count }}人</span>
                    </a-col>
                    <a-col :span="12">
                    <span class="t">开始时间：</span><span class="v">{{ detail.open_time }}</span>
                    </a-col>
                    <a-col :span="12">
                    <span class="t">退款人次：</span><span class="v">0</span>
                    </a-col>
                    <a-col :span="12">
                    <span class="t">结束时间：</span><span class="v">{{ detail.close_time }}</span>
                    </a-col>
                    <a-col :span="12">
                    <span class="t">报名人数：</span><span class="v">{{ detail.already_count }} / {{ detail.ticket_count }}人</span>
                    </a-col>
                </a-row>
            </a-card>
            <div class="main-box">
                <div class="clearfix table-tools">
                    <div class="buttons">
                        <a-form layout='inline'>
                            <a-form-item>
                                <a-button icon="export" @click="toExport" :loading="exportLoading">导出</a-button>
                            </a-form-item>
                            <a-form-item>
                                <a-button @click="toBack">返回</a-button>
                            </a-form-item>
                        </a-form>
                    </div>
                    <div class="search">
                        <a-form layout='inline' v-if="selectedRowKeys.length == 0">
                            <a-form-item>
                                <a-tooltip placement="topLeft" >
                                <template slot="title">
                                    <span>姓名/手机号</span>
                                </template>
                                <a-input v-model.trim="searchParams.search.name" placeholder="姓名/手机号" style="width: 140px" allowClear/>
                                </a-tooltip>
                            </a-form-item>
                            <!-- <a-form-item>
                                <a-tooltip placement="topLeft" >
                                <template slot="title">
                                    <span>手机号</span>
                                </template>
                                <a-input v-model.trim="searchParams.search.cellphone" placeholder="请输入手机号" style="width: 140px"/>
                                </a-tooltip>
                            </a-form-item> -->
                            <a-form-item>
                                <a-tooltip placement="topLeft" >
                                <template slot="title">
                                    <span>票务类型</span>
                                </template>
                                <a-select allowClear show-search @search="handleSearch" :filterOption="filterOption" v-model="searchParams.search.type_id" placeholder="请选择票务类型" style="width: 140px">
                                <a-select-option v-for="(d, index) of ticketType" :key="index" :value="d['type_id']">{{ d['filter_name'] }}</a-select-option>
                                </a-select>
                                </a-tooltip>
                            </a-form-item>

                            <a-form-item>
                                <a-tooltip placement="topLeft" >
                                <template slot="title">
                                    <span>报名时间</span>
                                </template>
                                <a-range-picker
                                style="width: 240px"
                                :ranges="rangesData"
                                v-model='searchData.add'
                                allowClear
                                @change="(val,time)=>handleTime(val,time,'add')" />
                                </a-tooltip>
                            </a-form-item>

                            <a-form-item>
                                <a-button @click="searchList" type="primary">筛选</a-button>
                            </a-form-item>
                        </a-form>
                        <a-form layout='inline' v-else>
                            <a-form-item>
                                已选择<span>{{selectedRowKeys.length}}</span>项
                            </a-form-item>
                            <a-form-item>
                                <a-divider type="vertical" />
                            </a-form-item>
                            <a-form-item>
                                <a-button @click="showTransfer()" icon="apartment">批量分配</a-button>
                            </a-form-item>
                        </a-form>


                    </div>
                </div>
                <!-- :rowSelection="{selectedRowKeys: selectedRowKeys, onChange: onSelectChange}" -->
                <div class="table">
                    <a-table size="small" :pagination="false" :bordered='false' rowKey="ticekt_id"
                    :selection="{key: 'ticekt_id'}"
                    :columns="columns" :dataSource="list" :scroll="{ x: 800 }"  @change="handleChange">
                        <template slot="index" slot-scope="text, record , index">
                            <span>{{(index+1) + pageParams.perPage * (pageParams.currentPage - 1)}}</span>
                        </template>
                        <template slot="related" slot-scope="text,record">
                            <a v-if="record.customer_detail" @click="customerClick($event,record.customer_detail)">{{record.customer_detail.customer_name}}</a>
                            <a v-else-if="record.leads_detail" @click="leadClick($event,record.leads_detail)">{{record.leads_detail.clue_name}}</a>
                            <span v-else>
                                -
                            </span>
                        </template>
                        <template slot="check_status" slot-scope="text,record">
                            <a-switch checkedChildren="已签到" unCheckedChildren="未签到" :checked="record.check_status == '1'" @change="changeStatus(record)"/>
                        </template>
                    </a-table>
                </div>
                <div class="page">
                    <a-pagination
                    :pageSizeOptions="pageSizeOptions"
                    :total="pageParams.totalCount"
                    showSizeChanger
                    :pageSize="pageParams.perPage"
                    v-model="current"
                    @change="changePage"
                    @showSizeChange="onShowSizeChange"
                    :showTotal="total => `共 ${pageParams.totalCount.toLocaleString()}条 `"
                    >
                        <template slot='buildOptionText' slot-scope='props'>
                        <span v-if="props.value!=='100'">{{props.value}}条/页</span>
                        <span v-if="props.value==='100'">100条/页</span>
                        </template>
                    </a-pagination>
                </div>
            </div>
        </div>
        <transfer :item='modalData' v-if="isTransfer"/>
        <followModal :authType="['crm','leads']" :item="followData" :leadsId='leadsId' :isTab='isTab' v-if="followVisible"/>
    </div>
</template>
s
<script>
import tableMixins from '@/common/mixins/table'
import ranges from "@/common/mixins/ranges"
import moment from 'moment'
import transfer from './transfer'
import followModal from '@/views/clientresource/modal/followModal/index'
    export default {
        name:'detail',
        mixins: [ tableMixins ,ranges],
        components: {
            transfer,
            followModal,
        },
        data() {
            return {
                isTab:'0',
                followVisible: false,
                followData:{},
                modalData:{},
                leadsId:'',
                list:[],
                columns:[],
                loading:false,
                meeting_id:'',
                detail: {},
                exportLoading: false,
                ticketType:[],
                pageSizeOptions: ['10', '20', '30', '40', '100'],
                current: 1,
                isTransfer:false,
                searchData:{
                    add:[]
                },
                pageParams: {
                    currentPage: 1,
                    pageCount: 50,
                    perPage: 10,
                    totalCount: 0
                },
                searchParams: {
                    "page": 1,
                    "per-page": 10,
                    "search": {},
                    "sort": ''
                },
            }
        },
        async created () {
            this.loading = true
            this.meeting_id = this.$route.query.meeting_id?this.$route.query.meeting_id:''
            this.getTicketType({meeting_id:this.meeting_id})
            await this.getDetail()
            await this.getList()
        },
        methods: {
            filterOption(input, option) {
                return option.componentOptions.children[0].text.toLowerCase().indexOf(input.toLowerCase()) >= 0
            },
            hideFollowModal() {
                this.followVisible = false
            },
            handleChange(pagination, filters, sorter){
                if(sorter.order){
                    if(sorter.order === "ascend"){
                        this.searchParams['sort'] = `${sorter.field}`
                    }else{
                        this.searchParams['sort'] = `-${sorter.field}`
                    }
                    }else{
                    this.searchParams['sort'] = ''
                    }
                this.getList()
            },
            customerClick(e,customer_detail){
                e && e.stopPropagation()
                this.isTab = '1'
                this.followData = customer_detail
                this.leadsId = customer_detail.task_id
                // if(item.client_type === 'leads'){
                //     if(item.leads_detail.customer_id){
                //         this.isTab = '1'
                //         this.modalData = item.leads_detail
                //     }else{
                //         this.isTab = '0'
                //         this.modalData = item.leads_detail
                //     }
                // }else{
                    
                // }
                // this.leadsId = item.task_id
                this.followVisible = true
                return 
            },
            leadClick(e,leads_detail){
                e && e.stopPropagation()
                this.isTab = '0'
                this.followData = leads_detail
                this.leadsId = leads_detail.task_id
                this.followVisible = true
                return 
            },
            handleTime(val,time,name){
                this.$forceUpdate()
                this.searchParams.search.start_date = time[0]
                this.searchParams.search.end_date = time[1]
            },
            showTransfer(item){
                if(item){
                    this.modalData = [item.id]
                }else{
                    this.modalData = this.selectedRowKeys
                }
                this.isTransfer = true
            },
            hideTransfer(num){
                if(num){
                    this.selectedRowKeys = []
                    this.getList()
                }
                this.isTransfer = false
            },
            async getDetail() {
                let res = await this.$store.dispatch('meetingCampaignDetailAction', {data: {meeting_id:this.meeting_id}})
                this.detail = res.data
                let arr = [
                    { title: '#', width:'55px', scopedSlots: { customRender: 'index' }, key: 'index'},
                    { title: '订单号', width:'150px',  dataIndex: 'out_trade_no', key: 'out_trade_no'}
                ]
                let columns = [
                    { title: '票务类型', width:'150px', dataIndex: 'type_id', key: '31' ,sorter:()=>{},ellipsis: true},
                    { title: '价格',width:'80px', dataIndex: 'ticket_price', key: '3' ,align:"center" },
                    { title: '购买票数',width:'100px', dataIndex: 'ticket_count', key: '4',align:"center" ,sorter:()=>{}},
                    { title: '报名时间',width:'100px', dataIndex: 'created_at', key: '5' ,sorter:()=>{},ellipsis: true},
                    { title: '报名状态',width:'100px', dataIndex: 'status', key: '6' ,sorter:()=>{}},
                    { title: '招生关联',width:'100px', dataIndex: 'related', align:'center' , key: 'related' , scopedSlots: { customRender: 'related' }},
                    { title: '来源',width:'100px', dataIndex: 'source_channel_id', key: 'source_channel_id' ,ellipsis: true ,sorter:()=>{},align:'center'},
                    { title: '签到状态',width:'100px', dataIndex: 'check_status',align:'right', key: '7' , scopedSlots: { customRender: 'check_status' },sorter:()=>{}},
                ]
                res.data.meeting_form.forEach(item=>{
                    arr.push({ title: item.filedName, dataIndex: item.filedDataName, key: item.filedDataName})
                })
                this.columns = [...arr,...columns]
            },
            async getList() {
                if(!this.meeting_id){
                    return false;
                }
                this.loading = true
                this.searchParams.meeting_id = this.meeting_id
                let res = await this.$store.dispatch('meetingTicketsAction', {data: this.searchParams})
                this.list = res.items
                this.pageParams = res._meta
                this.loading = false
            },
            async getTicketType(obj){
                let res = await this.$store.dispatch('searchCampaignTicketTypeAction', {params: obj})
                this.ticketType = res.data
            },
            async toExport(){
                this.exportLoading = true
                let search_params = {meeting_id:this.searchParams.meeting_id,search:this.searchParams.search};
                let res = await this.$store.dispatch('meetingCampaignDownloadExportListAction',search_params)
                const blob = new Blob([res], {type: 'application/vnd.ms-excel'})
                if ('download' in document.createElement('a')) { 
                    const link = document.createElement('a')
                    
                    link.download = `${this.detail.meeting_name}.${moment().format('YYYY.MM.DD')}.xlsx`
                    link.style.display = 'none'
                    link.href = URL.createObjectURL(blob)
                    document.body.appendChild(link)
                    link.click()
                    URL.revokeObjectURL(link.href) 
                    document.body.removeChild(link)
                } else { //其他浏览器
                    navigator.msSaveBlob(blob, fileName)
                }
                this.exportLoading = false
            },
            changeStatus(record) {
                if (record.check_status == '1') {
                    this.deleteItem(record)
                } else {
                    this.recoveryItem(record)
                }
            },
            deleteItem(item) { 
                let that = this
                return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('meetingTicketsUnCheckinAction', { data: { ticket_id: [item.ticekt_id] ,meeting_id:that.meeting_id }})
                        if (res) {
                            resolve(res)
                            item.check_status=0
                            this.$message.success("取消成功。")
                        }
                    }).catch(error => console.log(error));

                this.$confirm({
                    title: `您确定要取消签到"${item.student_name}"吗？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    
                    }
                })
            },
            recoveryItem(item) {
                let that = this
                return new Promise(async (resolve, reject) => {
                        let res = await that.$store.dispatch('meetingTicketsCheckinAction', { data: { ticket_id: [item.ticekt_id],meeting_id:that.meeting_id } })
                        if (res) {
                            resolve(res)
                            item.check_status=1
                            this.$message.success("签到成功。")
                        }
                    }).catch(error => console.log(error))
                    
                this.$confirm({
                    title: `您确定要签到"${item.student_name}"吗？`,
                    okText: '确定',
                    okType: 'danger',
                    cancelText: '取消',
                    onOk() {
                    
                    }
                })
            },
            handleSearch(e){
                let obj = {q:e,meeting_id:this.meeting_id}
                this.getTicketType(obj)
            },
            searchList(e) {
                e?e.preventDefault():''
                this.searchParams.page = 1
                this.current = 1
                this.getList()
            },
            changePage(page, pageSize) {
                this.searchParams.page = page
                this.getList()
            },
            onShowSizeChange(current, size) {
                this.selectedRowKeys = []
                this.searchParams['per-page'] = size
                this.$ls.set('perPage',size)
                this.getList()
            },
            toBack(){
                this.$router.go(-1)
            }
        },
    }
</script>

<style lang="scss" scoped>

</style>